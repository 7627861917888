import * as React from 'react';
import { checkSitePassword } from './src/lib/utils'
import PasswordProtect from './src/components/password-protect'
import "@fontsource/league-gothic"
import "@fontsource/merriweather"
import "@fontsource/poppins"
import "./src/css/animation.css"

export const wrapPageElement = () => {
  if( checkSitePassword() ) {
    return
  }

  return <PasswordProtect />
};
